"use client";

import React, { useState } from "react";
import { motion } from "framer-motion";
import { FiChevronDown } from "react-icons/fi";

const FAQ = () => {
  // Data for Column 1
  const faqsColumn1 = [
    {
      question: "What is Relationship Counselling?",
      answer: `Relationship counselling is a therapeutic service aimed at helping couples improve communication, resolve conflicts, and strengthen their bond. Whether you're facing challenges in communication, trust issues, or emotional disconnect, relationship counselling can provide a safe and supportive environment to address these concerns. Our registered psychologists in Calgary use proven techniques to help couples navigate their difficulties and foster a deeper understanding of one another. By focusing on mutual goals, relationship counselling can lead to healthier and more fulfilling relationships.`,
    },
    {
      question: "How can Marriage Counselling benefit us?",
      answer: `Marriage counselling is a specialized form of therapy designed to address the unique challenges that married couples face. It provides tools and strategies to improve communication, rebuild trust, and resolve conflicts effectively. In Calgary, our experienced marriage counsellors focus on creating a supportive space where couples can explore their concerns and work towards strengthening their marital bond. Whether you're dealing with long-standing issues or recent challenges, marriage counselling can help you reconnect with your partner and build a healthier, happier marriage.`,
    },
    {
      question: "What should we expect during Couples Counselling sessions?",
      answer: `Couples counselling sessions are tailored to address the specific needs of each couple. During these sessions, a registered psychologist will guide you and your partner through discussions to identify relationship challenges and set mutual goals. The sessions often involve improving communication, understanding each other's perspectives, and developing strategies to handle conflicts constructively. In Calgary, our couples counselling services are designed to help partners build trust, enhance emotional intimacy, and create a stronger foundation for their relationship.`,
    },
    {
      question: "How do we choose the best psychologist for our needs?",
      answer: `Choosing the best psychologist involves evaluating their credentials, experience, and areas of expertise. A registered psychologist has undergone rigorous training and is licensed to practice in Calgary. It's important to find someone who specializes in the type of therapy you require, such as relationship counselling, family counselling, or individual therapy. Equally important is the personal connection—you should feel comfortable and supported during your sessions. Our team of psychologists in Calgary is dedicated to providing compassionate, professional care tailored to your unique needs.`,
    },
    {
      question: "Is Family Counselling different from Couples Counselling?",
      answer: `Yes, family counselling and couples counselling serve distinct purposes. Family counselling involves working with multiple family members to address relational dynamics, improve communication, and resolve conflicts within the family unit. It aims to create a harmonious and supportive environment for all members. On the other hand, couples counselling focuses exclusively on the relationship between partners, addressing issues such as communication, trust, and intimacy. In Calgary, our psychologists specialize in both family and couples counselling to meet the diverse needs of our clients.`,
    },
    {
      question: "What Therapy Services do you offer?",
      answer: `At Alberta Counselling Services in Calgary, we provide a comprehensive range of therapy services tailored to meet your unique needs. Our offerings include individual therapy for personal growth, couples counselling to strengthen relationships, and family counselling to improve familial dynamics. We also specialize in marriage counselling, relationship therapy, and services for managing mental health concerns. Our team of registered psychologists is committed to delivering personalized, evidence-based care to support your journey towards well-being.`,
    },
    {
      question: "How can we schedule a session with a Marriage Counsellor?",
      answer: `Scheduling a session with a marriage counsellor at Alberta Counselling Services is simple and convenient. You can visit our [Contact Us](/contact) page to fill out an appointment request form or call our Calgary office directly. Our team will assist you in finding a suitable time for your session. Whether you're seeking help for communication issues, trust rebuilding, or other marital concerns, our experienced marriage counsellors are here to provide the support you need.`,
    },
    {
      question: "What are the qualifications of your Psychologists?",
      answer: `Our psychologists are registered professionals with advanced training and extensive experience in various areas of counselling. Each psychologist at our Calgary office holds the necessary credentials and is licensed to practice in Alberta. They specialize in fields such as relationship counselling, family counselling, marriage counselling, and individual therapy. With a commitment to ethical and compassionate care, our psychologists use evidence-based approaches to help clients achieve their goals.`,
    },
    {
      question: "How does Couples Counselling improve relationships?",
      answer: `Couples counselling provides a structured environment where partners can openly discuss their concerns and work towards resolving conflicts. It focuses on improving communication, understanding each other's needs, and developing strategies to strengthen the relationship. Our registered psychologists in Calgary use tailored approaches to help couples address challenges such as trust issues, emotional disconnect, and recurring arguments. The goal is to create a more harmonious and fulfilling partnership.`,
    },
    {
      question: "Why is Relationship Counselling important?",
      answer: `Relationship counselling is essential for addressing the challenges that can arise in any partnership. It provides a supportive space to explore issues such as communication barriers, trust concerns, and emotional intimacy. By working with a psychologist in Calgary, couples can gain valuable insights and tools to enhance their relationship and build a stronger connection. Whether you're navigating a rough patch or looking to strengthen your bond, relationship counselling offers significant benefits.`,
    },
  ];

  // Data for Column 2
  const faqsColumn2 = [
    {
      question: "What issues can Marriage Counselling address?",
      answer: `Marriage counselling can address a wide range of issues, including communication breakdowns, trust rebuilding, conflict resolution, and emotional disconnect. It is particularly beneficial for couples facing significant life changes, such as parenthood or relocation. Our marriage counselling services in Calgary are designed to help couples navigate these challenges and strengthen their relationship.`,
    },
    {
      question: "Is Couples Counselling suitable for unmarried partners?",
      answer: `Yes, couples counselling is suitable for both married and unmarried partners. It focuses on improving communication, resolving conflicts, and enhancing emotional intimacy. Our registered psychologists in Calgary provide a safe and supportive environment for all couples to explore their relationship dynamics and work towards mutual goals.`,
    },
    {
      question: "How can Family Counselling benefit our family?",
      answer: `Family counselling can help improve communication, resolve conflicts, and strengthen family bonds. It provides a safe space for family members to express their concerns and work towards mutual understanding. Our family counselling services in Calgary are designed to address a wide range of issues, including parenting challenges, sibling conflicts, and family transitions.`,
    },
    {
      question: "What is the role of a Registered Psychologist?",
      answer: `A registered psychologist is a licensed professional who provides psychological assessment, diagnosis, and treatment. They use evidence-based approaches to help individuals, couples, and families address mental health concerns and improve their well-being. Our registered psychologists in Calgary are committed to providing compassionate and professional care tailored to your unique needs.`,
    },
    {
      question: "How do I know if I need counselling?",
      answer: `Counselling can be beneficial for anyone experiencing emotional distress, relationship challenges, or personal growth concerns. If you're feeling overwhelmed, stuck, or unsure about how to move forward, counselling can provide valuable support and guidance. Our team of psychologists in Calgary is here to help you explore your concerns and work towards positive change.`,
    },
    {
      question: "What should I expect during my first counselling session?",
      answer: `During your first counselling session, you'll have the opportunity to discuss your concerns and goals with your psychologist. They will ask questions to better understand your situation and work with you to develop a personalized treatment plan. Our psychologists in Calgary are dedicated to creating a safe and supportive environment where you can feel comfortable sharing your thoughts and feelings.`,
    },
    {
      question: "How long does counselling typically last?",
      answer: `The duration of counselling varies depending on the individual's needs and goals. Some people may benefit from short-term counselling, while others may require longer-term support. Our psychologists in Calgary will work with you to determine the best approach for your unique situation and help you achieve your desired outcomes.`,
    },
    {
      question: "Can I receive counselling online?",
      answer: `Yes, we offer online counselling services for individuals, couples, and families. Our online sessions provide the same level of care and support as in-person sessions, allowing you to access counselling from the comfort of your own home. Our psychologists in Calgary are experienced in providing online therapy and are here to support you wherever you are.`,
    },
    {
      question: "What is the cost of counselling services?",
      answer: `The cost of counselling services varies depending on the type of therapy and the length of the session. We offer competitive rates for our services in Calgary and are committed to providing affordable and accessible care. Please contact us for more information about our fees and payment options.`,
    },
    {
      question: "How do I get started with counselling?",
      answer: `Getting started with counselling is easy. You can contact us by phone or email to schedule an appointment, or visit our website to fill out an online request form. Our team in Calgary is here to help you find the right psychologist and support you on your journey towards well-being.`,
    },
  ];

  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const toggleAccordion = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section
      id="faq"
      className="py-12 sm:py-16 lg:py-20"
      aria-labelledby="faq-heading"
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-8 lg:px-12">
        <h2
          id="faq-heading"
          className="text-3xl sm:text-4xl lg:text-5xl font-extrabold text-slate-800 text-center"
        >
          Frequently Asked Questions
        </h2>
        <p className="mt-4 max-w-3xl mx-auto text-lg text-slate-600 text-center">
          Discover more about our <strong>Calgary Therapy Services</strong> and how we can support your journey towards well-being.
        </p>

        <div className="mt-10 grid grid-cols-1 sm:grid-cols-2 gap-8">
          {/* Column 1 */}
          <div className="flex flex-col gap-y-5">
            {faqsColumn1.map((faq, index) => (
              <div
                key={index}
                className="border border-slate-200 rounded-md shadow-sm hover:shadow-md transition-shadow overflow-hidden"
              >
                <button
                  onClick={() => toggleAccordion(index)}
                  className="bg-white w-full flex justify-between items-center px-6 py-4 text-left text-slate-800 font-medium focus:outline-none"
                  aria-label={`Toggle answer for: ${faq.question}`}
                >
                  <span>{faq.question}</span>
                  <motion.div
                    animate={{
                      rotate: activeIndex === index ? 180 : 0,
                    }}
                    transition={{
                      duration: 0.3,
                      ease: [0.6, 0, 0.2, 1],
                    }}
                    className="text-slate-600"
                  >
                    <FiChevronDown size={24} />
                  </motion.div>
                </button>
                <motion.div
                  initial={false}
                  animate={{
                    height: activeIndex === index ? "auto" : 0,
                    opacity: activeIndex === index ? 1 : 0,
                  }}
                  className="overflow-hidden border-t border-slate-200"
                  transition={{
                    duration: 0.4,
                    ease: [0.6, 0, 0.2, 1],
                  }}
                >
                  <div className="bg-slate-50 px-6 py-4 text-slate-600 text-sm">
                    {faq.answer}
                  </div>
                </motion.div>
              </div>
            ))}
          </div>

          {/* Column 2 */}
          <div className="flex flex-col gap-y-5">
            {faqsColumn2.map((faq, index) => {
              const adjustedIndex = index + faqsColumn1.length;
              return (
                <div
                  key={adjustedIndex}
                  className="border border-slate-200 rounded-md shadow-sm hover:shadow-md transition-shadow overflow-hidden"
                >
                  <button
                    onClick={() => toggleAccordion(adjustedIndex)}
                    className="bg-white w-full flex justify-between items-center px-6 py-4 text-left text-slate-800 font-medium focus:outline-none"
                    aria-label={`Toggle answer for: ${faq.question}`}
                  >
                    <span>{faq.question}</span>
                    <motion.div
                      animate={{
                        rotate: activeIndex === adjustedIndex ? 180 : 0,
                      }}
                      transition={{
                        duration: 0.3,
                        ease: [0.6, 0, 0.2, 1],
                      }}
                      className="text-slate-600"
                    >
                      <FiChevronDown size={24} />
                    </motion.div>
                  </button>
                  <motion.div
                    initial={false}
                    animate={{
                      height:
                        activeIndex === adjustedIndex ? "auto" : 0,
                      opacity:
                        activeIndex === adjustedIndex ? 1 : 0,
                    }}
                    className="overflow-hidden border-t border-slate-200"
                    transition={{
                      duration: 0.4,
                      ease: [0.6, 0, 0.2, 1],
                    }}
                  >
                    <div className="bg-slate-50 px-6 py-4 text-slate-600 text-sm">
                      {faq.answer}
                    </div>
                  </motion.div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQ;
